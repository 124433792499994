<template>
  <v-container fluid class="pa-0">
    <h3 class="mb-4">{{$_strings.mou.LOCATION_DATA}}</h3>
    <span>{{$_strings.mou.LOCATION}}</span>
    <div v-if="showBtnChangeHistory">
      <v-btn
        color="red"
        class="pa-0 caption"
        text
        @click="showDialogChangeHistory"
      >
        {{$_strings.mou.THERE_ARE_CHANGES}}
        <v-icon class="pl-4" size="15">mdi-information-outline</v-icon>
      </v-btn>
    </div>
    <v-row class="mt-2 mb-4">
      <v-col cols="12">
        <v-data-table
          item-key="id"
          class="elevation-1"
          :server-items-length="totalDataLocation"
          :options.sync="pagination"
          :loading="isLoading"
          :headers="displayedHeaderLocation"
          :items="mouLocations"
          :item-class="itemRowBackground"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.latLong`]={item}>
            <span>
              {{`Lat: ${item.latitude} Long: ${item.longitude}`}}
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <DialogChangeHistoryLocationAndTransportTypes
      :mouId="mouId"
      ref="dialogChangeHistory"
    />
  </v-container>
</template>

<script>

import DialogChangeHistoryLocationAndTransportTypes from '../../Dialog/ChangeHistoryLocationAndTransportTypes/index.vue';

export default {
  name: 'mou-location-page',
  components: {
    DialogChangeHistoryLocationAndTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouLocations: {
      type: Array,
      default: () => [],
    },
    totalDataLocation: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      headersTableLocation: [
        {
          text: this.$_strings.mou.LOCATION_NAME,
          value: 'locationName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: `${this.$_strings.mou.CITY}/${this.$_strings.mou.DISTRICT}`,
          value: 'locationCityName',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.ADDRESS,
          value: 'address',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
        },
        {
          text: this.$_strings.mou.GOOGLE_MAPS,
          value: 'latLong',
          class: 'white--text primary text-capitalize',
          cellClass: 'clickable',
          sortable: false,
        },
      ],
      pagination: {
        page: 1,
        itemsPerPage: 10,
      },
      isShowBtnChangeHistory: false,
      isCreated: false,
    };
  },
  watch: {
    pagination: {
      handler(newVal) {
        const isDelete = !this.isPageInbox ? false : null;
        this.$emit('fetchMouLocation', newVal, isDelete);
      },
      deep: true,
    },
    mouLocations: {
      handler(newVal) {
        const findLoc = newVal.find((m) => m.status === 'DELETE' || m.status === 'ADD');
        if (this.isPageInbox && !findLoc && !this.isShowBtnChangeHistory && !this.isCreated) {
          this.fetchData();
        }
      },
      deep: true,
    },
  },
  computed: {
    displayedHeaderLocation() {
      return this.headersTableLocation.filter((header) => {
        if (this.readonly && header.value === 'action') return;
        return header;
      });
    },
    showBtnChangeHistory() {
      //  TO DO
      //    1. should contains status add or delete and page is inbox
      if (!this.isPageInbox) return false;
      if (this.mouLocations.find((m) => m.status === 'DELETE' || m.status === 'ADD')) return true;
      return this.isShowBtnChangeHistory;
    },
  },
  methods: {
    async fetchData() {
      const filters = {
        page: 1,
        size: 10,
        sort: 'status,asc',
      };
      filters.isDelete = false;
      let result = await this.$_services.mou.getMouLocation(filters, { mouId: this.mouId });
      let find = result.data.contents.find((d) => d.status === 'DELETE' || d.status === 'ADD');
      if (!find) {
        filters.isDelete = true;
        result = await this.$_services.mou.getMouLocation(filters, { mouId: this.mouId });
        find = result.data.contents.find((d) => d.status === 'DELETE');
      }
      if (find) this.isShowBtnChangeHistory = true;
      this.isCreated = true;
    },
    itemRowBackground(item) {
      if (!this.isPageInbox) return;
      if (item.index % 2 === 0 && item.status === 'DELETE') return 'red lighten-4';
      if (item.index % 2 === 1 && item.status === 'DELETE') return 'red lighten-5';
      if (item.status === 'ADD') return 'green lighten-5';
    },
    showDialogChangeHistory() {
      this.$refs.dialogChangeHistory.type = 'location';
      this.$refs.dialogChangeHistory.dialog = true;
    },
  },
};

</script>
