<template>
  <v-form
    ref="form"
    lazy-validation
    disabled
  >
    <v-row v-show="type === 'companyId'" class="mt-5">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="ma-0">{{$_strings.mou.COMPANY_NAME_TABLE_HEADER_LABEL}}</label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="textInitial"
          disabled
          class="body-2"
          v-model="form.companyName"
          required
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'companyAbbreviation'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0">
          {{$_strings.mou.ALIAS_INITIAL_TABLE_HEADER_LABEL}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="textInitial"
          disabled
          class="body-2"
          v-model="form.companyAbbreviation"
          required
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'contractNo'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0">
          {{$_strings.mou.NO_CONTRACT}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          class="body-2"
          v-model="form.contractNo"
          placeholder="Autogenerate"
          required
          disabled
          dense
          outlined
          filled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'mouNo'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="mouNumber">
          {{$_strings.mou.MOU_NUMBER_TABLE_HEADER_LABEL}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="mouNumber"
          class="body-2"
          v-model="form.mouNo"
          placeholder="Autogenerate"
          required
          disabled
          dense
          outlined
          filled
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'parentMouNo'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="mouParentNumber">
          {{$_strings.mou.MOU_PARENT_NUMBER_TABLE_HEADER_LABEL}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="mouParentNumber"
          class="body-2"
          :value="form.parentMouNo || '-'"
          required
          disabled
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === '_periodicCooperation'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="periodicCooperation">
          {{$_strings.mou.PERIODIC_COOPERATION}}
        </label>
      </v-col>
      <v-col cols="auto" sm="3" class="pt-0">
        <v-text-field
          id="periodicCooperation"
          dense
          outlined
          :value="form.mouPeriodStart ? dateFormat(form.mouPeriodStart) : ''"
          class="body-2"
          placeholder="Dari tanggal"
          prepend-inner-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto" class="pt-0">
        <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
        </span>
      </v-col>
      <v-col cols="auto" sm="3" class="pt-0">
        <v-text-field
          dense
          outlined
          :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
          class="body-2"
          placeholder="Sampai tanggal"
          prepend-inner-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === '_mouPeriodStartDoc'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="periodicCooperation">
          {{$_strings.mou.PERIODE_START_DOC}}
        </label>
      </v-col>
      <v-col cols="auto" sm="3" class="pt-0">
        <v-text-field
          dense
          outlined
          :value="form.mouPeriodStartDoc ? dateFormat(form.mouPeriodStartDoc) : ''"
          class="body-2"
          placeholder="Dari tanggal"
          prepend-inner-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
      <v-col cols="12" sm="auto" class="pt-0">
        <span class="body-2 ma-0">{{$_strings.mou.TO}} <v-icon color="red" class="icon_important">mdi-asterisk</v-icon>
        </span>
      </v-col>
      <v-col cols="auto" sm="3" class="pt-0">
        <v-text-field
          dense
          outlined
          :value="form.mouPeriodEnd ? dateFormat(form.mouPeriodEnd) : ''"
          disabled
          class="body-2"
          placeholder="Sampai tanggal"
          prepend-inner-icon="mdi-calendar"
          readonly
        ></v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'isAutoExtend'" class="mt-4">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="autoExtendMonth">
          {{$_strings.mou.AUTO_EXTENDOR}}
        </label>
      </v-col>
      <v-col cols="auto" class="mt-0 pt-0">
        <v-radio-group
          class="mt-0 pa-0"
          row
          v-model="form.isAutoExtend"
        >
          <v-radio
            :key="1"
            :value="true"
            label="Ya"
          ></v-radio>
          <v-radio
            :key="2"
            :value="false"
            label="Tidak"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          class="body-2"
          :value="form.autoExtendMonth || '-'"
          required
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'termOfPayment'" class="mt-5">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="termOfPayment">
          {{$_strings.mou.TERM_OF_PAYMENT}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          class="body-2"
          :value="form.termOfPayment || '-'"
          required
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'paymentMethod'" class="mt-4">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="paymentMethod">
          {{$_strings.mou.PAYMENT_METHOD}}
        </label>
      </v-col>
      <v-col cols="auto" class="mt-0 pt-0">
        <v-radio-group
          class="mt-0 pa-0"
          row
          v-model="radioButtonPaymentMethod"
        >
          <v-radio
            :value="1"
            label="Ya"
          ></v-radio>
          <v-radio
            :value="2"
            label="Tidak"
          ></v-radio>
        </v-radio-group>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-if="radioButtonPaymentMethod === 1"
          id="paymentMethod"
          class="body-2"
          v-model="form.paymentMethod"
          dense
          disabled
          outlined>
        </v-text-field>
        <v-text-field
          v-else
          id="paymentMethod"
          class="body-2"
          v-model="form.paymentMethod"
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === '_paymentPic'" class="mt-4">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="paymentPic">
          {{$_strings.mou.PAYMENT_PIC}}
        </label>
      </v-col>
      <v-col cols="12" sm="auto" class="mt-0 pt-0">
        <v-select
          v-model="form.paymentPicSalutation"
          outlined
          class="body-2"
          dense
          :items="picTitleItems"
        ></v-select>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="paymentPic"
          v-model="form.paymentPic"
          class="body-2"
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'paymentPicMsisdn'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="paymentPicMsisdn">
          {{$_strings.mou.PAYMENT_PIC_NUMBER_PHONE}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <phone-number-format
          id="paymentPicMsisdn"
          v-model="form.paymentPicMsisdn"
          dense
          class="body-2"
          outlined
          disabled
          :required="false"
        >
        </phone-number-format>
      </v-col>
    </v-row>
    <v-row v-show="type === '_operationalPic'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="paymentOperational">
          {{$_strings.mou.OPERATIONAL_PIC}}
        </label>
      </v-col>
      <v-col cols="12" sm="auto" class="mt-0 pt-0">
        <v-select
          v-model="form.operationalPicSalutation"
          outlined
          class="body-2"
          dense
          :items="picTitleItems"
        ></v-select>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          v-model="form.operationalPic"
          id="paymentOperational"
          class="body-2"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'operationalPicMsisdn'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="operationalPicMsisdn">
          No Telepon PIC Operasional
        </label>
      </v-col>
      <v-col class="pt-0">
        <phone-number-format
          id="operationalPicMsisdn"
          v-model="form.operationalPicMsisdn"
          dense
          class="body-2"
          outlined
          disabled
          :required="false"
        >
        </phone-number-format>
      </v-col>
    </v-row>
    <v-row v-show="type === 'operationalPicJobTitle'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="operationalPicJobTitle">
          Posisi PIC Operasional
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="operationalPicJobTitle"
          dense
          class="body-2"
          outlined
          v-model="form.operationalPicJobTitle"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'isInsurance'" class="mt-4">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="clientInsurance">
          {{$_strings.mou.CLIENTS_USING_INSURANCE}}
        </label>
      </v-col>
      <v-col cols="auto" class="mt-0 pt-0">
        <v-radio-group
          id="clientInsurance"
          class="mt-0 pa-0"
          row
          v-model="form.isInsurance"
        >
          <v-radio
            :value="true"
            label="Ya"
          ></v-radio>
          <v-radio
            :value="false"
            label="Tidak"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-show="type === 'isAuditedByKap'" class="mt-4">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="clientInsurance">
          {{$_strings.mou.FINANCIAL_STATEMENTS_KAP}}
        </label>
      </v-col>
      <v-col cols="auto" class="mt-0 pt-0">
        <v-radio-group
          id="clientInsurance"
          class="mt-0 pa-0"
          row
          v-model="form.isAuditedByKap"
        >
          <v-radio
            :value="true"
            label="Ya"
          ></v-radio>
          <v-radio
            :value="false"
            label="Tidak"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row v-show="type === 'totalAsset'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="totalAsset">{{$_strings.mou.TOTAL_ASSET}}</label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="totalAsset"
          v-mask="{'alias': 'idr-currency',rightAlign: false}"
          prefix="Rp"
          :value="formatAsCurrency(form.totalAsset)"
          dense
          outlined>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'ventureCapital'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="ventureCapital">{{$_strings.mou.STARTUP_CAPITAL}}</label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          prefix="Rp"
          id="ventureCapital"
          v-mask="{'alias': 'idr-currency',rightAlign: false}"
          dense
          outlined
          :value="formatAsCurrency(form.ventureCapital)"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'shareholder'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="shareHolder">
          {{$_strings.mou.SHAREHOLDERS}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="shareHolder"
          v-model="form.shareholder"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'bankName'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="bankName">
          {{$_strings.mou.BANK_NAME}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="bankName"
          v-model="form.bankName"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'bankBranch'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="bankBranch">
          {{$_strings.mou.DISTRIBUTARY}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="bankBranch"
          v-model="form.bankBranch"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === '_bankAccountName'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="accountOwner">
          {{$_strings.mou.ACCOUNT_OWNER}}
        </label>
      </v-col>
      <v-col cols="12" sm="auto" class="mt-0 pt-0">
        <v-select
          v-model="form.bankAccountNameSalutation"
          outlined
          class="body-2"
          dense
          :items="accountOwnerItems"
        ></v-select>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="bankAccountName"
          v-model="form.bankAccountName"
          class="body-2"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === 'bankAccountNo'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="accountNumber">
          {{$_strings.mou.ACCOUNT_NUMBER}}
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-text-field
          id="accountNumber"
          v-mask="{'mask': '9', 'repeat': 30, allowMinus: false, rightAlign: false}"
          v-model="form.bankAccountNo"
          dense
          outlined
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row v-show="type === '_typeOfService'" class="mt-0">
      <v-col class="pt-0" cols="12" sm="3">
        <label class="body-2 ma-0" for="typeOfService">
          Service Types
        </label>
      </v-col>
      <v-col class="pt-0">
        <v-checkbox
          class="ma-0"
          label="Intergrated Service"
          color="primary"
          v-model="form.isIntegratedService"
          hide-details
        ></v-checkbox>
        <v-checkbox
          label="Transportation"
          color="primary"
          v-model="form.isTransportation"
          hide-details
        ></v-checkbox>
        <div class="pl-8" v-if="form.isTransportation">
          <v-checkbox
            v-model="form.isMultiPick"
            label="Multipick"
            color="primary"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="form.isMultiDrop"
            label="Multidrop"
            color="primary"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="form.isBackhauling"
            label="Backhauling"
            color="primary"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="form.isMultiModa"
            label="Multimoda"
            color="primary"
            hide-details
          ></v-checkbox>
          <v-checkbox
            v-model="form.isMultiShipper"
            label="Multishipper"
            color="primary"
            hide-details
          ></v-checkbox>
        </div>
        <v-checkbox
          label="Warehousing"
          color="primary"
          v-model="form.isWarehousing"
          hide-details
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row v-show="type === '_p2pLending'">
      <v-col class="pt-1" cols="12" sm="3">
        <label ref="_p2pLending" class="body-2" for="isP2pLending">
          {{$_strings.mou.P2P_LENDING}}
        </label>
      </v-col>
      <v-col cols="auto" class="pt-0">
        <v-checkbox
          class="mt-0"
          v-model="form.isP2pLending"
        />
      </v-col>
      <v-col cols="12" sm="8" class="pl-0 pt-0">
        <v-data-table
          :headers="displayedHeaderP2PLanding"
          :items="form.p2pLending"
          item-key="id"
          hide-default-footer
        >
          <template v-slot:[`item.top`]=items>
            <span>{{items.item.top}} Hari</span>
          </template>
          <template v-slot:[`item.adminRate`]=items>
            <span>{{replaceDotToComma(items.item.adminRate)}} %</span>
          </template>
          <template v-slot:[`item.interestRate`]=items>
            <span>{{replaceDotToComma(items.item.interestRate)}} %</span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { dateFormat } from '@/helper/commonHelpers';

export default {
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      picTitleItems: ['Bpk', 'Ibu'],
      accountOwnerItems: ['Bpk', 'Ibu', 'PT', 'CV'],
      headersP2PLanding: [
        {
          text: this.$_strings.mou.TOP,
          value: 'top',
          sortable: false,
        },
        {
          text: this.$_strings.mou.ADMIN_RATE,
          value: 'adminRate',
          sortable: false,
        },
        {
          text: this.$_strings.mou.INTEREST_RATE,
          value: 'interestRate',
          sortable: false,
        },
      ],
    };
  },
  computed: {
    radioButtonPaymentMethod: {
      get() {
        const { paymentMethod } = this.form;
        if (paymentMethod === 'TRANSFER') return 1;
        return 2;
      },
      set() {},
    },
    displayedHeaderP2PLanding() {
      return this.headersP2PLanding.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  methods: {
    dateFormat,
    formatAsCurrency(value) {
      const formatNumber = Intl.NumberFormat('id-ID');
      return formatNumber.format(value) || 0;
    },
    twoDigitComma(number) {
      const splitNumber = String(number).split('.');
      let _number = number;
      if (splitNumber.length > 1) {
        if (splitNumber[1].length > 2) {
          _number = parseFloat(number).toFixed(2);
        }
      }
      return parseFloat(_number);
    },
    replaceDotToComma(number) {
      const _number = this.twoDigitComma(number);
      const numberToString = _number.toString();
      return numberToString && numberToString.toString().indexOf('.') > -1 ? numberToString.toString().replace('.', ',') : numberToString;
    },
  },
};
</script>
