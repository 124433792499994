<template>
  <v-container fluid>
    <common-error
      v-if="errors.isErrorMouLocation"
      @fetchData="errors.isErrorMouLocation = false"
    />
    <mou-location
      v-else
      :mouId="mouId"
      :isPageInbox="isPageInbox"
      :isLoading="loaders.isLoadingMouLocation"
      :mouLocations="mouLocations"
      :totalDataLocation="totalDataLocation"
      @fetchMouLocation="(pagination, isDelete) => $emit('fetchMouLocation', pagination, isDelete)"
    />
    <common-error
      v-if="errors.isErrorMouTransportTypes"
      @fetchData="errors.isErrorMouTransportTypes = false"
    />
    <mou-transport-types
      v-else
      :mouId="mouId"
      :isPageInbox="isPageInbox"
      :isLoading="loaders.isLoadingMouTransportTypes"
      :mouTransportTypes="mouTransportTypes"
      :totalDataTransportTypes="totalDataTransportTypes"
      @fetchMouTransportType="(pagination, isDelete) => $emit('fetchMouTransportType', pagination, isDelete)"
    />
    <v-row>
      <v-col cols="12" class="d-flex mt-5 justify-end">
        <v-btn @click="$emit('changeStep', 0)" class="mr-2" outlined color="primary">
          {{$_strings.common.BACK}}
        </v-btn>
        <v-btn @click="$emit('changeStep', 2)" color="primary">
          {{$_strings.common.NEXT}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MouLocation from './MouLocation.vue';
import MouTransportTypes from './MouTransportTypes.vue';

export default {
  name: 'location-vehicle-page',
  components: {
    MouLocation,
    MouTransportTypes,
  },
  props: {
    isPageInbox: {
      type: Boolean,
      default: false,
    },
    loaders: {
      type: Object,
      default: () => {},
    },
    errors: {
      type: Object,
      default: () => {},
    },
    mouId: {
      type: Number,
      default: 0,
    },
    mouLocations: {
      type: Array,
      default: () => [],
    },
    mouTransportTypes: {
      type: Array,
      default: () => [],
    },
    totalDataLocation: {
      type: Number,
      default: 0,
    },
    totalDataTransportTypes: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};

</script>
