<template>
  <v-container class="white" fluid>
    <main>
      <!-- FIXED PRICE -->
      <section v-if="form.isPriceFixed">
        <v-checkbox
          :label="$_strings.mou.FIXED_PRICE"
          disabled
          v-model="form.isPriceFixed"
          class="w-30"
        ></v-checkbox>
        <FixedPrice
          :isPageInbox="isPageInbox"
          :mouId="mouId"
        />
      </section>
      <!-- FCL -->
      <section v-if="checkboxServiceType.isFCL">
        <common-error
          v-if="errors.isErrorFclMouRoute"
          @fetchData="errors.isErrorFclMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            label="FCL"
            class="w-30"
            disabled
            v-model="checkboxServiceType.isFCL"
          ></v-checkbox>
          <FilterData
            :filters="filterMouRoute.fcl"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchFcl"
            @updateMouRouteLocationItems="updateMouRouteLocationItems"
          />
          <FCL
            :isPageInbox="isPageInbox"
            :form="form"
            :mouId="mouId"
            :reFetchData="reFetchFcl"
            :isLoading="loaders.isLoadingFclMouRoute"
            :mouFclRoute="mouFclRoute"
            :totalFclRouteData="totalFclRouteData"
            :fclMouRouteSequence="fclMouRouteSequence"
            @fetchMouFclRoute="fetchMouFclRoute"
          />
        </section>
      </section>
      <!-- Backhauling -->
      <section v-if="checkboxServiceType.isBackhauling || mouFclBackhaulingRoute.length > 0">
        <common-error
          v-if="errors.isErrorFclBackhaulingMouRoute"
          @fetchData="errors.isErrorFclBackhaulingMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            :label="$_strings.order.BACKHAULING"
            disabled
            v-model="checkboxServiceType.isBackhauling"
            class="w-30"
          ></v-checkbox>
          <FilterData
            :filters="filterMouRoute.fclBackhauling"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchFclBackhauling"
            @updateMouRouteLocationItems="updateMouRouteLocationItems"
          />
          <Backhauling
            :isPageInbox="isPageInbox"
            :form="form"
            :mouId="mouId"
            :reFetchData="reFetchFclBackhauling"
            :mouFclBackhaulingRoute="mouFclBackhaulingRoute"
            :totalFclBackhaulingRouteData="totalFclBackhaulingRouteData"
            :isLoading="loaders.isLoadingFclBackhaulingMouRoute"
            :fclMouRouteSequence="fclMouRouteSequence"
            @fetchMouFclBackhaulingRoute="fetchMouFclBackhaulingRoute"
          />
        </section>
      </section>
      <!-- LCL -->
      <section v-if="checkboxServiceType.isLCL">
        <common-error
          v-if="errors.isErrorLclMouRoute"
          @fetchData="errors.isErrorLclMouRoute = false"
        />
        <section v-else>
          <v-checkbox
            disabled
            label="LCL"
            v-model="checkboxServiceType.isLCL"
            class="w-30"
          ></v-checkbox>
          <FilterData
            :filters="filterMouRoute.lcl"
            :mouId="mouId"
            :pageFiltersLocation="pageFilters.mouRouteLocation"
            :itemsLocationMouRoute="itemsLocationMouRoute"
            @fetchData="fetchLcl"
            @updateMouRouteLocationItems="updateMouRouteLocationItems"
          />
          <LCL
            :isPageInbox="isPageInbox"
            :form="form"
            :reFetchData="reFetchLcl"
            :mouLclRoute="mouLclRoute"
            :totalLclRouteData="totalLclRouteData"
            :isLoading="loaders.isLoadingLclMouRoute"
            :lclMouRouteSequence="lclMouRouteSequence"
            @fetchMouLclRoute="fetchMouLclRoute"
          />
        </section>
      </section>
    </main>
    <v-row>
      <v-col cols="12">
        <v-btn @click="$emit('changeStep', 1)" class="mr-2" outlined color="primary">
          {{$_strings.common.BACK}}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { skipEmptyStringObject } from '@/helper/commonHelpers';
import FixedPrice from './FixedPrice';
import Backhauling from './Backhauling';
import LCL from './LCL';
import FCL from './FCL';
import FilterData from './FilterData.vue';

export default {
  name: 'price-list-page',
  components: {
    FixedPrice,
    FCL,
    Backhauling,
    LCL,
    FilterData,
  },
  props: {
    isPageInbox: {
      typeof: Boolean,
      default: false,
    },
    form: {
      typeof: Object,
      default: {},
    },
    mouId: {
      typeof: Number,
      default: 0,
    },
    checkboxServiceType: {
      typeof: Object,
      default: {},
    },
  },
  data() {
    return {
      isLoadingGetMou: false,
      isErrorGetMou: false,
      reFetchFcl: 0,
      reFetchFclBackhauling: 0,
      reFetchLcl: 0,
      pageFilters: {
        fclServiceType: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
        fclBackhaulingServiceType: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
        lclServiceType: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
        mouRouteLocation: {
          search: '',
          page: 0,
          size: 50,
          totalData: 0,
        },
      },
      // loaders
      loaders: {
        isLoadingFclMouRoute: false,
        isLoadingFclBackhaulingMouRoute: false,
        isLoadingLclMouRoute: false,
      },
      // errors
      errors: {
        isErrorFclMouRoute: false,
        isErrorFclBackhaulingMouRoute: false,
        isErrorLclMouRoute: false,
      },
      // sequence
      fclMouRouteSequence: [],
      lclMouRouteSequence: [],
      defaultFilters: {
        page: 1,
        itemsPerPage: 10,
      },
      filterMouRoute: {
        fcl: {
          originId: '',
          destinationId: '',
        },
        fclBackhauling: {
          originId: '',
          destinationId: '',
        },
        lcl: {
          originId: '',
          destinationId: '',
        },
      },
      mouFclRoute: [],
      mouFclBackhaulingRoute: [],
      mouLclRoute: [],
      itemsLocationMouRoute: [],
      // ITEMS FOR FILTER
      itemsFclServiceType: [],
      itemsFclBackhaulingServiceType: [],
      itemsLclServiceType: [],

      totalLclRouteData: 0,
      totalFclRouteData: 0,
      totalFclBackhaulingRouteData: 0,
    };
  },
  methods: {
    skipEmptyStringObject,
    updateMouRouteLocationItems(items) {
      this.itemsLocationMouRoute = [...this.itemsLocationMouRoute, ...items];
    },
    updateFclServiceTypeItems(newItems) {
      const newLocation = [...this.itemsFclServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsFclServiceType = [...this.itemsFclServiceType, ...newLocation];
    },
    updateFclBackhaulingServiceTypeItems(newItems) {
      const newLocation = [...this.itemsFclBackhaulingServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsFclBackhaulingServiceType = [...this.itemsFclBackhaulingServiceType, ...newLocation];
    },
    updateLclServiceTypeItems(newItems) {
      const newLocation = [...this.itemsLclServiceType];
      newItems.forEach((newLocationData) => {
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.originLocationId)) {
          newLocation.push({
            locationId: newLocationData.originLocationId,
            locationName: newLocationData.originLocationName,
          });
        }
        if (!newLocation.find((oldLocation) => oldLocation.locationId === newLocationData.destinationLocationId)) {
          newLocation.push({
            locationId: newLocationData.destinationLocationId,
            locationName: newLocationData.destinationLocationName,
          });
        }
      });
      this.itemsLclServiceType = [...this.itemsLclServiceType, ...newLocation];
    },
    fetchLcl() {
      this.reFetchLcl += 1;
    },
    fetchFcl() {
      this.reFetchFcl += 1;
    },
    fetchFclBackhauling() {
      this.reFetchFclBackhauling += 1;
    },
    setSequenceFclMouRoute(routes, page, size) {
      routes.forEach((route, index) => {
        if (!this.fclMouRouteSequence.find((x) => x[route.sequenceOrderNo])) {
          this.fclMouRouteSequence.push({
            [route.sequenceOrderNo]: (size * page) - (size - (index + 1)),
          });
        }
      });
    },
    setSequenceLclMouRoute(routes, page, size) {
      routes.forEach((route, index) => {
        if (!this.lclMouRouteSequence.find((x) => x[route.sequenceOrderNo])) {
          this.lclMouRouteSequence.push({
            [route.sequenceOrderNo]: (size * page) - (size - (index + 1)),
          });
        }
      });
    },
    async fetchMouFclRoute(pagination, isDelete = null) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.fcl,
      };
      if (isDelete !== null) filters.isDelete = isDelete;
      const serviceType = 'FCL';
      try {
        this.loaders.isLoadingFclMouRoute = true;
        this.errors.isErrorFclMouRoute = false;
        const result = await this.$_services.mou.getMouRoute(this.skipEmptyStringObject(filters), { mouId, serviceType });
        this.mouFclRoute = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
        this.totalFclRouteData = result.data.totalData;
      } catch {
        this.errors.isErrorFclMouRoute = true;
      } finally {
        this.loaders.isLoadingFclMouRoute = false;
      }
    },
    async fetchMouFclBackhaulingRoute(pagination) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.fclBackhauling,
      };
      const serviceType = 'FCL_BACKHAULING';
      try {
        this.loaders.isLoadingFclBackhaulingMouRoute = true;
        this.errors.isErrorFclBackhaulingMouRoute = false;
        const result = await this.$_services.mou.getMouRoute(this.skipEmptyStringObject(filters), { mouId, serviceType });
        this.totalFclBackhaulingRouteData = result.data.totalData;
        if (result.data.contents.length) {
          this.checkboxServiceType.isBackhauling = true;
        }
        this.mouFclBackhaulingRoute = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
      } catch {
        this.errors.isErrorFclBackhaulingMouRoute = true;
      } finally {
        this.loaders.isLoadingFclBackhaulingMouRoute = false;
      }
    },
    async fetchMouLclRoute(pagination) {
      const { page, itemsPerPage: size } = pagination || this.defaultFilters;
      const { mouId } = this;
      const filters = {
        page: page - 1,
        size,
        ...this.filterMouRoute.lcl,
      };
      const serviceType = 'LCL';
      try {
        this.loaders.isLoadingLclMouRoute = true;
        this.errors.isErrorLclMouRoute = false;
        const result = await this.$_services.mou.getMouRoute(this.skipEmptyStringObject(filters), { mouId, serviceType });
        this.totalLclRouteData = result.data.totalData;
        this.mouLclRoute = result.data.contents.map((item, index) => ({
          ...item,
          index,
        }));
      } catch (e) {
        this.errors.isErrorLclMouRoute = true;
      } finally {
        this.loaders.isLoadingLclMouRoute = false;
      }
    },
  },
};

</script>
